.nav-item.active {
  background-color: #f6f9fc;
  border-left-style: solid;
  border-left-color: #748bfc;
}

.nav-item.active > a > .nav-link{
  font-weight: 600;
  color: #364866;
}

.nav-item > a > .nav-link > .icon > i{
  font-size: .875rem!important
}

.form-control{
  background-color: #f2f2f2 !important;
}

.lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.lato-black {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.lato-thin-italic {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.lato-light-italic {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.lato-regular-italic {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.lato-bold-italic {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.lato-black-italic {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.hind-light {
  font-family: "Hind", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.hind-regular {
  font-family: "Hind", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.hind-medium {
  font-family: "Hind", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.hind-semibold {
  font-family: "Hind", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.hind-bold {
  font-family: "Hind", sans-serif;
  font-weight: 700;
  font-style: normal;
}
.table-sticky th {
  background: #fff;
  position: sticky;
  top: -1px;
  z-index: 990;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}

.progress-bar-filled {
  height: 10px;
  background-color: #4cacaf;
  transition: width 0.3s;
}





.pagination {
  display: flex;
  justify-content: right;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
  display: inline-block;
}

.pagination li a {
  color: #797979;
  text-decoration: none;
  font-weight: bold;
  padding: 8px 12px;
  border: #ffffff;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.pagination li a:hover {
  background-color: #f0f0f0;
}

.pagination .active a {
  background-color: #748bfc;
  color: white !important;
  border-color: #748bfc;
}

.pagination .disabled a {
  pointer-events: none;
  color: #ccc;
  background-color: #eee;
  border-color: #ffffff;
}

.btn-sexo .active{
  background-color: #e9ecef;
}


.container-background-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-card-container {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}


.bg-outline-primary{
  color: #5e72e4 !important;
  background-color: #e4e6f2 !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  font-weight: 600;
}

.nav-tabs .nav-link{
  color: #495057;
}
