body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.body-fade{
  animation: fade-in .3s;
}


@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.dropdown-toggle.btn.btn-primary::after {
  display: none !important;
}

.dropdown-toggle.btn.btn-primary {
  box-shadow: none !important;
}


.dropdown .dropdown-menu.show:before{
  display:none;
}

@media (min-width: 992px) {
  :not(.main-content) .navbar .dropdown .dropdown-menu {
      top: 1rem !important;
  }
}

